<template functional>
  <div
    class="xl:mx-12 my-4 border rounded-md border-gray-400 w-full p-8 bg-white"
  >
    <header class="mb-4">
      <h2>
        {{ parent.$t(`${$options.tPfx}.secondPhase`) }}
      </h2>
      <p class="visa-prices-text">
        {{ parent.$t(`${$options.tPfx}.visaPricesDocument`) }}
        <u> <a href="/documents/tarifat-e-vizes.pdf"> {{ parent.$t(`${$options.tPfx}.here`) }} </a></u>
      </p>
    </header>
    <p class="mb-2">
      {{ parent.$t(`${$options.tPfx}.paidAndApproved`) }}
    </p>
    <ol class="list-outside list-decimal pl-6">
      <li class="mb-1">
        <i18n :path="`${$options.tPfx}.evaluationProcess`">
          <template #rejected>
            <strong>{{ parent.$t(`${$options.tPfx}.rejected`) }}</strong>
          </template>
        </i18n>
      </li>
      <li class="mb-1">
        {{ parent.$t(`${$options.tPfx}.emailNotification`) }}
      </li>
      <li class="mb-1">
        <i18n :path="`${$options.tPfx}.approvedVisa`">
          <template #ifVisaIsApproved>
            <strong class="text-green-500">{{ parent.$t(`${$options.tPfx}.ifVisaIsApproved`) }}</strong>
          </template>
          <template #entering>
            <strong class="bg-green-400">{{ parent.$t(`${$options.tPfx}.entering`) }}</strong>
          </template>
          <template #leaving>
            <strong class="bg-red-400">{{ parent.$t(`${$options.tPfx}.leaving`) }}</strong>
          </template>
        </i18n>
      </li>
      <li class="mb-1">
        <i18n :path="`${$options.tPfx}.rejectedVisa`">
          <template #ifVisaIsRejected>
            <strong class="text-secondary-500">{{ parent.$t(`${$options.tPfx}.ifVisaIsRejected`) }}</strong>
          </template>
        </i18n>
      </li>
    </ol>
  </div>
</template>

<script>
export const translationPrefix = "view.apply.processPhases.secondPhase";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      secondPhase: "Second phase",
      paidAndApproved:
        "After the applicant pays the visa fee, the following steps are undertaken:",
      evaluationProcess:
        "The applicant is notified via email that the application is in the “evaluation process” stage",
      emailNotification:
        "The applicant is notified via the e-Visa system in their email of the decision of the responsible state authorities.  The assessment process as a rule takes up to 15 working days and in exceptional cases it can take up to 30 working days",
      approvedVisa:
          "{ifVisaIsApproved}, the electronic visa is generated by the e-Visa system and sent by email to the applicant. You must be in possession of the visa at the Border Crossing Point of the Republic of Albania, when {entering} and {leaving} the territory",
      ifVisaIsApproved:
        "If the visa is approved",
      entering:
        "entering",
      leaving:
          "leaving",
      rejectedVisa:
          "{ifVisaIsRejected} the applicant is informed via their email with the legal arguments for the visa rejection.",
      ifVisaIsRejected:
        "If the visa is rejected:",
      appealForTheDecision:
          "Within 5 working days from the receipt of the decision, the interested foreigner has the right to appeal against the decision of refusal / annulment of the visa with the   relevant   arguments   and   evidence,   as   provided   for   in   Law   79/2021   \"On Foreigners\", only at the following email address:",
      email:
          "visa@mfa.gov.al",
      responsibleAuthority:
          "The only authority responsible for reviewing requests for appealing decisions is: ",
      directorateOfConsular:
          "The Directorate for Consular Affairs at the Ministry for Europe and Foreign Affairs",
      visaPricesDocument:
        "Visa fees for foreign citizens according to VKM no. 329, dated 29.05.2024, you can find the desired type of visa: ",
      here:
          "here",
    },
    al: {
      secondPhase: "Faza e dytë",
      paidAndApproved:
        "Pas kryerjes së pagesës së tarifës së vizës nga aplikuesi, procedohet si vijon:",
      evaluationProcess:
        "Aplikuesi nëpërmjet emailit njoftohet, që stadi i aplikimit të tij është në “proces vlerësimi”",
      emailNotification:
        "Aplikuesi njoftohet nga sistemi e-Visa nëpërmjet emailit për vendimin e autoriteteve përgjegjëse shtetërore. Procesi i vlerësimit, si rregull zgjat deri në 15 ditë pune, por në raste përjashtimore mund të zgjasë deri në 30 ditë pune",
      endOfEvaluation:
        "Pas përfundimit të procesit të vlerësimit i cili zgjat deri në 15 ditë, dhe për raste përjashtimore deri në  30  ditë  konsulli  lajmëron  aplikuesin  nëpërmjet  emailit  për  vendimin  e  autoriteteve  përgjegjëse shtetërore",
      approvedVisa:
        "{ifVisaIsApproved},viza elektronike gjenerohet nga sistemi e-Visa dhe dërgohet me\n" +
          "email aplikantit. Ju duhet ta jeni i pajisur me vizën përkatëse kur të jeni në Pikën e Kalimit\n" +
          "Kufitar të Republikës së Shqipërisë-së, në {entering} dhe {leaving} nga territori",
      ifVisaIsApproved:
          "Nëse viza është miratuar",
      entering:
          "hyrje",
      leaving:
          "dalje",
      rejectedVisa:
        "{ifVisaIsRejected} Aplikuesi njoftohet nëpërmjet emailit me argumentin ligjor për refuzimin e vizës.",
      ifVisaIsRejected:
          "Nëse viza është refuzuar: ",
      appealForTheDecision:
          "Brenda 5 ditë pune nga marrja e vendimit, i huaji i interesuar ka të drejtë të apelojë kundër vendimit të refuzimit/anulimit të vizës me argumentet dhe provat përkatëse, siç parashikohet në ligjin 79/2021 “Për të huajt”, vetëm në adresën elektronike (email): ",
      email:
          "visa@mfa.gov.al",
      responsibleAuthority:
          "Autoriteti i vetëm përgjegjës për shqyrtimin e kërkesave për apelimin e vendimeve është: ",
      directorateOfConsular:
          "Drejtoria e Çështjeve Konsullore pranë Ministrisë për Evropën dhe Punët e Jashtme",
      visaPricesDocument:
          "Tarifat e vizës për shtetasit e huaj sipas VKM nr. 329, datë 29.05.2024 për llojin e deshiruar të vizës i gjeni: ",
      here:
        "këtu",
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>

<style>
.card-for-email {
  border-color: red;
  border-width: 3px;
  text-align: center;
  padding: 15px;
}
.email-text {
  color: #03507e;
}

.visa-prices-text {
  color: #0595ed;
  font-weight: bold;
  font-size: 18px;
}
</style>